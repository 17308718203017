
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { auth } from '../../helpers/Firebase';
import { apiUrl } from '../../constants/defaultValues';
import  decodeJwt  from 'jwt-decode';
import  swal  from 'sweetalert';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError
} from './actions';


export function* watchLoginUser() {
   
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);



function* loginWithEmailPassword({ payload }) {
    
    const { email, password } = payload.user;
    const { history } = payload;
   
    try {
       
        
        const loginUser = yield axios.post(apiUrl+"/admin/login",payload.user)
        
        if(loginUser.data.success == 0){
           // swal
           swal("Oops!", loginUser.data.message, "error");
           //swal(loginUser.data.message);
           yield put(loginUserError(loginUser.data.message));
           return false;
        }else{
            //console.log("testestddddd",loginUser);
            const decodedToken = decodeJwt(loginUser.data.token);
            localStorage.setItem('token', loginUser.data.token);
            localStorage.setItem('role', decodedToken.user_data.role_id)
            //localStorage.setItem('user_id', decodedToken.user_data.id);
            let idealtime = localStorage.getItem('backendidealtime');
            var now = new Date();
            var milliseconds = new Date().getTime() + (1 * 60 * 60 * 1000);
            //var milliseconds = new Date().getTime() + (60000);
            if(idealtime){
                
                if(idealtime < now.getTime()){
                    history.push('/login');
                }else{
                    localStorage.setItem('backendidealtime',milliseconds);
                }
            }else{
                localStorage.setItem('backendidealtime',milliseconds);
            }
            yield put(loginUserSuccess(loginUser.data));
            //return false;
            history.push('/');
        }
        // const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        // if (!loginUser.message) {
        //     localStorage.setItem('user_id', loginUser.user.uid);
        //     yield put(loginUserSuccess(loginUser.user));
        //     history.push('/');
        // } else {
        //     console.log("testestesteee")
        //     yield put(loginUserError(loginUser.message));
        // }
    } catch (error) {
        
       // yield put(loginUserError(error));
        swal("Oops!", "Something went wrong!", "error");
        return false;
    }
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
   
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('backendidealtime');
        
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    const { history } = payload;
    try {
        // const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        // if (!forgotPasswordStatus) {
        //     yield put(forgotPasswordSuccess("success"));
        // } else {
        //     yield put(forgotPasswordError(forgotPasswordStatus.message));
        // }
        const loginUser = yield axios.post(apiUrl+"/admin/forgotpassword",payload.forgotUserMail)
        
        if(loginUser.data.status == 0){
           // swal
           swal("Oops!", loginUser.data.message, "error");
           //swal(loginUser.data.message);
           yield put(forgotPasswordError(email));
           return false;
        }else{            
            swal("Mail sent!","Please check email to reset password.", "success");
            yield put(forgotPasswordSuccess(""));
            history.push('/')
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode,history } = payload;
    try {
        // const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        // if (!resetPasswordStatus) {
        //     yield put(resetPasswordSuccess("success"));
        // } else {
        //     yield put(resetPasswordError(resetPasswordStatus.message));
        // }
        const loginUser = yield axios.post(apiUrl+"/admin/resetpasswordreq",payload)
        
        if(loginUser.data.status == 0){
           // swal
           swal("Oops!", loginUser.data.message, "error");
           //swal(loginUser.data.message);
           yield put(resetPasswordError(""));
           return false;
        }else{            
            swal("Password Reset","Password changed successfully!", "success");
            yield put(resetPasswordSuccess(""));
            history.push('/')
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}